import React ,{ Component }from "react";
import { IoLogoGameControllerB } from "react-icons/io";
import { FaMobileAlt,FaGlobe } from 'react-icons/fa';

const ServiceList = [
    {
        icon: <IoLogoGameControllerB />,
        title: 'Game Development',
        description: 'Produce games that are groundbreaking, enjoyable, and emotionally compelling.'
    },
    {
        icon: <FaMobileAlt  />,
        title: 'App Development',
        description: 'Build intuitive apps that engage users through responsive and captivating design.'
    },
    {
        icon: <FaGlobe />,
        title: 'Change the World',
        description: 'Utilize technology to enhance society and assist humanity.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/*<a href="/service-details">*/}
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/*</a>*/}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
