import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="React Creative Agency" />;


const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Game Development',
        title: 'Beer Cheer',
        description: 'A crafty lil beer game.',
        link: 'https://www.beer-cheer.com'
    },
    {
        image: Portfolio_image2,
        category: 'App Development',
        title: 'PushUpz',
        description: 'One Push at a time.',
        link: 'https://www.pushupz.com'
    },
    {
        image: Portfolio_image3,
        category: 'Trading Cards',
        title: 'Garbage Ale Cans',
        description: 'Beer brewer parody.',
        link: 'https://www.teepublic.com/user/beercheer'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.link}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.link}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.link}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={{ pathname: value.link }} target="_blank"></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;