import React, { Component } from "react";
import { GiScorpion } from "react-icons/gi";

class About extends Component{
    render(){
        let title = 'Stubborn as a Scorpion.',
        description = 'They told me I would not make it as a developer. They said I would never become an engineer.',
        description2 = 'I just keep writing lines, and eating Mom\'s spaghetti.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <div className="icon">
                                            <GiScorpion />
                                        </div>
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn">
                                            <a className="btn-transparent" href="#contact">
                                                <span>LET'S TALK</span>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;